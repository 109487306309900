import { Container, Row, Col } from "react-bootstrap";
import screenImage from '../assets/img/screen.png';

export const HowToBuy = () => {
  const method1Steps = [
    "Create a wallet: For this, we recommend using MetaMask, a wallet which can be conveniently added as an extension to your browser.",
    "Get some ETH: You can buy ETH directly on MetaMask or transfer it from an exchange such as Coinbase.",
    "Go to Uniswap: Access the decentralized exchange Uniswap. Ensure to select the correct network that your token resides on.",
    "Swap ETH for PEPEGAME: On Uniswap, find the PEPEGAME token using its contract address. You can then swap your ETH for PEPEGAME."
  ];

  const method2Steps = [
    "Create a wallet: Similar to Method 1, we recommend using MetaMask.",
    "Get a super coolio Spacebapepe NFT on Opensea: https://opensea.io/collection/spacebapepe",
    "Play the Runner game: Connect your wallet and enjoy our addictive Runner game and earn points as you play.",
    "Claim rewards: Once you've gathered enough points, you can claim your PEPEGAME token rewards directly to your MetaMask wallet.",
  ];

  return (
    <section className="howtobuy" id="howtobuy">
      <Container>
        <Row>
          <Col lg={6}>
            <h2>How to Buy $PEPEGAME</h2>
            <ol>
              {method1Steps.map((step, index) => (
                <li key={index}>{step}</li>
              ))}
            </ol>
          </Col>
          <Col lg={6}>
            <h2>How to Earn $PEPEGAME</h2>
            <ol>
              {method2Steps.map((step, index) => (
                <li key={index}>{step}</li>
              ))}
            </ol>
          </Col>
        </Row>
        <Row>
          <Col>
            <img src={screenImage} alt="Screen" /> {/* Include your image here */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
