import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const Tokenomics = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="tokenomics" id="tokenomics">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="tokenomics-bx wow zoomIn">
              <h2>Tokenomics</h2>
              <p>Our tokenomics model has been designed to ensure fair and transparent distribution, as well as to incentivize gameplay and reward our team.</p>
              <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme tokenomics-slider">
                <div className="item">
                  <h1>35%</h1>
                  <h5>Presale</h5>
                </div>
                <div className="item">
                  <h1>35%</h1>
                  <h5>Liquidity Pool</h5>
                </div>
                <div className="item">
                  <h1>20%</h1>
                  <h5>Game</h5>
                </div>
                <div className="item">
                  <h1>2.5%</h1>
                  <h5>Team</h5>
                </div>
                <div className="item">
                  <h1>2.5%</h1>
                  <h5>Advisors</h5>
                </div>
                <div className="item">
                  <h1>5%</h1>
                  <h5>Marketing, Giveaways, Exchange Listing</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
