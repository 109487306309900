import React from 'react';
import { Container } from 'react-bootstrap';

export const Disclaimer = () => (
  <section id="disclaimer" className="disclaimer-section">
    <Container>
      <h2>Disclaimer</h2>
      <p className="disclaimer-text">
        <br />
        $PEPEGAME is a meme coin based on the SpaceBapepe NFT collection and has utility for use as an in game currency in the Confluence Universe.
        <br />
        The coin is for entertainment purposes only.
        <br />
        Official Email: thepepegame@gmail.com
      </p>
    </Container>
  </section>
);
