import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { HowToBuy } from './components/HowToBuy';
import { Tokenomics } from './components/Tokenomics';
import { Roadmap } from './components/Roadmap';
import { Footer } from './components/Footer';
import { Disclaimer } from './components/Disclaimer';
import Presale from './components/Presale';

import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';

function App() {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);

  useEffect(() => {
    const startApp = async () => {
      const provider = await detectEthereumProvider();

      if (provider) {
        console.log('Ethereum provider detected!');
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);

        // Get accounts
        const accounts = await web3Instance.eth.getAccounts();
        setAccount(accounts[0]);

        // Subscribe to accounts change
        window.ethereum.on('accountsChanged', function (accounts) {
          setAccount(accounts[0]);
        });
      } else {
        console.error('Please install MetaMask!');
      }
    };

    startApp();
  }, []);

  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Presale web3={web3} account={account} />
      <Tokenomics />
      <HowToBuy />
      <Roadmap />
      <Disclaimer />
      <Footer />
    </div>
  );
}

export default App;
