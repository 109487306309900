import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import stage1 from '../assets/img/stage-1.jpg';
import stage2 from '../assets/img/stage-2.jpg';
import stage3 from '../assets/img/stage-3.jpg';

export const Roadmap = () => {

  const roadmapStages = [
    {
      stage: "PHASE 1",
      points: [
        "Boost our meme coin's visibility and make it a trendsetter on Twitter.",
        "Launch our first Play-to-Earn game 'Save The Toads'.",
        "Showcase our exciting Play-to-Earn system to gamers worldwide.",
        "Gain 1,000 unique holders within the $PEPEGAME coin",
        "Host fun, interactive contests to generate hype, memes, and attract more users."
      ],
      image: stage1
    },
    {
      stage: "PHASE 2",
      points: [
        "Work on listing our tokens on top-tier centralized exchanges.",
        "Organize joint promotional events to foster community engagement with other projects.",
        "Launch exclusive merchandise using $PEPEGAME ecosystem.",
        "Gain 10,000 unique holders within the $PEPEGAME coin",
        "Launch mobile Play-to-Earn game version for 'Save The Toads'."
      ],
      image: stage2
    },
    {
      stage: "PHASE 3",
      points: [
        "Explore opportunities to develop unique NFT collection powered by $PEPECOIN.",
        "Explore strategic partnerships with emerging and popular metaverses.",
        "Gain 50,000 unique holders within the $PEPEGAME coin",
        "Begin development of our second Play-to-Earn Pepe video game."
      ],
      image: stage3
    },
  ];


  return (
    <section className="roadmap" id="roadmap">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Roadmap</h2>
                  <p>Our roadmap describes the stages and plans for our memecoin. Each stage represents a significant milestone in the development and growth of our project.</p>
                  <Tab.Container id="roadmap-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Stage 1 - Launch</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Stage 2 - Partnerships</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Stage 3 - Expansion</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <ul>
                          {
                            roadmapStages[0].points.map((point, index) => {
                              return (
                                <li key={index}>{point}</li>
                              )
                            })
                          }
                        </ul>
                        <div className="centerImage">
                          <img src={roadmapStages[0].image} alt="Stage 1" />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <ul>
                          {
                            roadmapStages[1].points.map((point, index) => {
                              return (
                                <li key={index}>{point}</li>
                              )
                            })
                          }
                        </ul>
                        <div className="centerImage">
                          <img src={roadmapStages[1].image} alt="Stage 2" />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <ul>
                          {
                            roadmapStages[2].points.map((point, index) => {
                              return (
                                <li key={index}>{point}</li>
                              )
                            })
                          }
                        </ul>
                        <div className="centerImage">
                          <img src={roadmapStages[2].image} alt="Stage 3" />
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
